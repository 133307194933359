import React from 'react';
import './Loading.css';

export const Loading = () => {
  return (
    <div>
        <div class="center-body">
  <div class="loader-spanne-20">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>

    </div>
  )
}
export default Loading;